import PropTypes from "prop-types"
import React, { useRef, useEffect } from 'react'

import '../css/tutorial.css'
import lookAroundImg from '../img/look360.png'

function Tutorial ({ show }) {
    const elRef = useRef(null)

    useEffect(() => {
        if (show === true) {
            elRef.current.classList.add('tutorial__show')
            elRef.current.classList.add('tutorial__fadeinout')

            setTimeout(() => {
                elRef.current.classList.remove('tutorial__show')
                elRef.current.classList.remove('tutorial__fadeinout')
            }, 4000)
        }
    }, [show])


    return (
        <div className='tutorial' ref={elRef}>
            <div className="tutorial__image"><img src={lookAroundImg} alt="look around" /></div>
        </div>
    )
}

Tutorial.propTypes = {
    show: PropTypes.any
}

export default Tutorial
