import React from 'react'

import '../css/paragraph.css'

import pravniciImg from '../img/pravnici.jpg'

function Paragraph () {
    return (
        <div className="paragraph">
            <div className="paragraph__picture">
                <img src={pravniciImg} alt="paragraph hanka" />
            </div>
            <div className="paragraph__quote">
                &quot;Clara pacta, boni amici / Clear agreements, good friends.&quot;
                <span className="paragraph__quote_signature">Latin proverb</span>
            </div>
            <div className="paragraph__title">Terms of Use Agreement</div>
            <div className="paragraph__text">
                This Agreement sets forth the standards of use of the interactive online gallery ArtStudio (the “Site”) operated by Hana Auerova (the “Operator”). By using the Site, the visitor (the “Visitor”) agrees to the terms and conditions embodied within this Agreement. If the Visitor does not agree to the terms and conditions of this Agreement, he/she should immediately cease all usage of the Site.
            <br /><br /><br />
            1.	The Agreement Modifications. The Operator reserves the right, at any time, to modify, alter, or update the terms and conditions of this Agreement without prior notice. Modifications shall become effective immediately upon being posted at the Site. The Visitor’s continued use of the Site after amendments are posted constitutes an acknowledgement and acceptance of the Agreement and its modifications. Except as provided in this paragraph, this Agreement may not be amended.
            <br /><br />
            2.	Description of Service. The Operator is providing Visitor with access to use the Site and virtually wander around the gallery placed there as well as purchase the exposed artwork via the [platform for buying the artwork] governed by the terms set forth in there. Each Visitor must provide (1) all equipment necessary for his / her own Internet connection and (2) provide for Visitor’s access to the Internet, and (3) pay any fees related to such connection.
            <br /><br />
            3.	Description of artworks. All physical artworks are owned by the Operator or the licensor and are subject to Czech and International copyright laws. Moreover, the Operator states that none of the artworks violate the rights of any third party and all the artworks on the Site are for illustrative purposes only and their colours may slightly differ due to the Visitor’s computer display settings
            <br /><br />
            4.	Disclaimer of Warranties. The Site is provided by the Operator on an “as is” and on an “as available” basis. To the fullest extent permitted by applicable law, the Operator makes no representations or warranties of any kind, express or implied, regarding the use or the results of the Site in terms of its correctness, accuracy, reliability, or otherwise. The Operator shall have no liability for any interruptions in the use of the Site. The Operator disclaims all warranties regarding the information provided, including the implied warranties of merchantability and fitness for a particular purpose, and non-infringement. Some jurisdictions do not allow the exclusion of implied warranties; therefore, the above-referenced exclusion may be inapplicable.
            <br /><br />
            5.	Limitation of Liability. The Operator shall not be liable for any damages whatsoever, and in particular the Operator shall not be liable for any special, indirect, consequential, or incidental damages, or damages for lost profits, loss of revenue, or loss of use, arising out of or related to the Site or the information contained in it, whether such damages arise in contract, negligence, tort, unter statute, in equity, at law, or otherwise, even if the Operator has been advised of the possibility of such damages. Some jurisdictions do not allow for the limitation or exclusion of liability for incidental or consequential damages; therefore some of the above limitations may be inapplicable.
            <br /><br />
            6.	Indemnification. The Visitor agrees to indemnify and hold the Operator, its parents, subsidiaries, affiliates, officers and employees, harmless from any claim or demand, including attorneys’ fees and costs, made by any third party due to or arising out of Visitor’s use of the Site, the violation of this Agreement, or violation of any law by Visitor.
            <br /><br />
            7.	Modifications and Interruption to Site. The Operator reserves the right to modify or discontinue the Site with or without notice to the Visitor. The Operator shall not be liable to Visitor or any third party should the Operator exercise its right to modify or discontinue the Site. The Visitor acknowledges and accepts that the Operator does not guarantee continuous, uninterrupted or secure access to the Site, and operation of the Site may be interfered with or adversely affected by numerous factors or circumstances outside of Operator’s control.
            <br /><br />
            8.	Governing Jurisdiction of the Courts of the Czech Republic. The Site is operated and provided in the Czech Republic. As such, the Operator is subject to the laws of the Czech Republic (reflecting the laws of the European Union), and such laws will govern this Agreement, without giving effect to any choice of law rules. The Operator makes no representation that the Site or other services are appropriate, legal or available for use in other locations. Accordingly, if the Visitor chooses to access the Site, he / she agrees to do so subject to the internal laws of the Czech Republic.
            <br /><br />
            9.	Compliance with Laws. The Visitor assumes all knowledge of applicable law and is responsible for compliance with any such laws. Visitor may not use the Site in any way that violates applicable national, European-union or international laws, regulations or other government requirements. The Visitor further agrees not to transmit any material that encourages conduct that could constitute a criminal offense, give rise to civil liability or otherwise violate any applicable national, European-union or international law or regulation.
            <br /><br />
            10.	Copyright Information. All the artworks on the Site as well as text, graphics, logos, button icons, images, audio clips and software (the “Content”) are either owned or licensed by the Operator (and the Operator owns or is executing the rights to the extent necessary for this Agreement including but not limited to all Czech copyright rights, in and to all the Contented presented on the Site) and may not be reproduced, republished, redistributed, sold, rent or otherwise sub-licensed, duplicated, copied, edited, modified or otherwise exploited or used in any manner without the express written permission of the Operator and, if appropriate, the relevant artist in case of licensed Content. All rights to the Site and its Content are reserved to the Operator.
            <br /><br />
            11.	Trademark Information. Some of the Content may be protected by trademarks. If that is the case, the Operator is the owner of those trademarks and they may not be used without prior written permission of the Operator.
            <br /><br />
            12.	Contact Information. If the Visitor believes that any Content infringes the copyright, trademark rights or rights related, or if the Visitor desires to use the Content in any way excluded by this Agreement, he / she can provide the Operator with such information via hana.auerova@seznam.cz.
            <br /><br />
            13.	Botnets. The Operator retains the right, at his sole discretion, to terminate any accounts involved with botnets and related activities. If any hostnames are used as command and control points for botnets, the Operator reserves the right to direct the involved hostnames to a honeypot, loopback address, logging facility, or any other destination at the Operator’s discretion.
            <br /><br />
            14.	Privacy policy. The Operator hereby states that no personally identifiable information are processed by visiting the Site within the meaning of General Data Protection Regulation (EU) 2016/679 and related legislation.
            <br /><br />
            15.	Other Terms. If any provision of this Agreement shall be unlawful, void or unenforceable for any reason, the other provisions (and any partially-enforceable provision) shall not be affected thereby and shall remain valid and enforceable to the maximum possible extent. The Visitor agrees that this Agreement and any other agreements referenced herein may be assigned by the Operator in its sole discretion, to a third party in the event of a merger or acquisition. This Agreement shall apply in addition to, and shall not be superseded by, any other written agreement between the Operator and Visitor in relation to his / her participation as a Visitor.
            <br /><br />
            This Terms of Use Agreement (the “Agreement”) was last updated and is effective on March 25, 2021.
            <br /><br />
            For further inquiries please contact us at <b>hana.auerova@seznam.cz</b>
                <br /><br /><br /><br />
            </div>
        </div>
    )
}

export default Paragraph
