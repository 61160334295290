import React, {
    useState,
    useEffect,
    useRef,
    useContext,
} from 'react'

import PropTypes from "prop-types"

import { config } from './Constants'

import AppContext from './AppContext'

import nftImg from './img/nft.png'
import vrImg from './img/vrlab2.png'
import exhibitImg from './img/exhibit.png'
import kruzitkoImg from './img/kruzitko2.png'
import docsImg from './img/dokumentace.png'
import shopBagImg from './img/shopbag.png'
import bookImg from './img/book.png'

const Image = ({ src }) => {
    return (
        <img src={src} />
    )
}

Image.propTypes = {
    src: PropTypes.any
}

import TaskbarItem from './components/TaskbarItem'

import MyMarzipano from './components/MyMarzipano'
import ReactAudioPlayer from 'react-audio-player'
import GenericModal from './components/GenericModal'
import CloseX from './components/CloseX'
// import ByAi from './components/MadeByAi'
import Enter from './components/Enter'
import TopTaskbar from './components/TopTaskbar'
import Taskbar from './components/Taskbar'
import EscapeElement from './components/EscapeElement'
import Tutorial from './components/Tutorial'
import Ribbon from './components/Ribbon'
import Ribbon2 from './components/Ribbon2'
import NightLayer from './components/NightMode'
import Ribbon3 from "./components/Ribbon3"

function App () {
    const { state, dispatch } = useContext(AppContext)

    const [scenes, setScenes] = useState({})
    // eslint-disable-next-line no-unused-vars
    const [loaderVisibility, setLoaderVisibility] = useState(true)
    const audioPlayer = useRef(null)
    const [playing, setPlaying] = useState(false)
    const [userClicked, setUserClicked] = useState(false)
    // const [byAi, setByAi] = useState(false)
    const refLoaderTimestamp = useRef(Math.floor(Date.now() / 1000))
    const refLoaderIntervalStorage = useRef(null)

    useEffect(() => {
        async function fetchData () {
            const res = await fetch(`${config.url.API_URL}/definitions`)
            res.json().then(data => {
                console.log(data.scenes)
                setScenes(data.scenes)
            })
        }

        fetchData()
    }, [])

    function openModal (contentName) {
        dispatch({
            type: 'SET_GENERIC_MODAL_IS_OPEN',
            payload: true
        })

        dispatch({
            type: 'SET_GENERIC_MODAL_CONTENT',
            payload: contentName
        })
    }

    function openPictureModal (data) {
        // setPictureModalIsOpen(true)
        // setModalData(data)

        dispatch({
            type: 'SET_GENERIC_MODAL_IS_OPEN',
            payload: true
        })

        dispatch({
            type: 'SET_GENERIC_MODAL_CONTENT',
            payload: 'painting'
        })

        dispatch({
            type: 'SET_PICTURE_MODAL_CONTENT',
            payload: data
        })
    }

    function loaderOn () {
        setLoaderVisibility(true)
    }

    function loaderOff () {
        // console.log('LoaderOff')
        if (loaderVisibility) {
            if (Math.floor(Date.now() / 1000) - refLoaderTimestamp.current > 5) {
                setLoaderVisibility(false)
            } else {
                refLoaderIntervalStorage.current = setInterval(() => {
                    // console.log('interval', Math.floor(Date.now() / 1000) - refLoaderTimestamp.current)
                    if (Math.floor(Date.now() / 1000) - refLoaderTimestamp.current > 5) {
                        setLoaderVisibility(false)
                        clearInterval(refLoaderIntervalStorage.current)
                    }
                }, 500)
            }
        }
    }

    function togglePlayMusic () {
        if (playing === false) {
            audioPlayer.current.audioEl.current.play()
            setPlaying(true)
        } else {
            audioPlayer.current.audioEl.current.pause()
            setPlaying(false)
        }
    }

    function userFirstTimeClick () {
        if (userClicked === false) {
            togglePlayMusic()
            setUserClicked(true)
        }
    }

    return (
        <div className='app' onClick={userFirstTimeClick} onTouchEnd={userFirstTimeClick}>
            <Ribbon />
            <Ribbon2 />
            {/*<Ribbon3 />*/}
            <div className="floatingBar">
                {/*<TaskbarItem
                    icon={<Image src={kruzitkoImg} />}
                    text=""
                    onClick={() => {
                        dispatch({
                            type: 'SET_GENERIC_MODAL_IS_OPEN',
                            payload: true
                        })

                        dispatch({
                            type: 'SET_GENERIC_MODAL_CONTENT',
                            payload: 'vila'
                        })
                    }}
                />*/}
                <TaskbarItem
                    icon={<Image src={vrImg} />}
                    text=""
                    onClick={() => {
                        window.open("https://www.virtualtravel.cz/panomenumaker/iframe-vr.php?vr=rqspGSU0GT")
                    }}
                />
                <TaskbarItem
                    icon={<Image src={nftImg} />}
                    text=""
                    onClick={() => {
                        window.open("https://opensea.io/collection/ai-photography-hana-auerova")
                    }}
                />

                <TaskbarItem
                    icon={<Image src={exhibitImg} />}
                    text=""
                    onClick={() => {
                        state.changeScene('scene20', { yaw: 2.4939150385556896, pitch: -0.056301698220664775 })
                    }}
                />

                <TaskbarItem
                    icon={<Image src={docsImg} />}
                    text=""
                    onClick={() => {
                        window.open(`${config.url.API_URL}/files/THE BOOK CZ Ing. Novakova Hana FINAL.pdf`)
                    }}
                />

                <TaskbarItem
                    icon={<Image src={bookImg} />}
                    text=""
                    onClick={() => {
                        window.open(`${config.url.API_URL}/files/THE BOOK Ing. Novakova Hana 2024 FINAL EN.pdf`)
                    }}
                />

                <TaskbarItem
                    icon={<Image src={shopBagImg} />}
                    text=""
                    onClick={() => {
                        openModal('shop')
                    }}
                />
            </div>
            <TopTaskbar
                closeFunc={() => dispatch({
                    type: 'SET_GENERIC_MODAL_IS_OPEN',
                    payload: false
                })}
            />
            <NightLayer light={state.light} />
            <AppContext.Consumer>
                {(context) => (
                    <MyMarzipano
                        scenes={scenes}
                        openPictureModal={openPictureModal}
                        loaderControl={{ loaderOn, loaderOff }}
                        // isItAi={setByAi}
                        context={context}
                    />
                )}
            </AppContext.Consumer>
            <Taskbar
                music={{
                    isPlaying: playing,
                    togglePlay: togglePlayMusic,
                }}
                closeGenericModal={() => dispatch({
                    type: 'SET_GENERIC_MODAL_IS_OPEN',
                    payload: false
                })}
            />
            <EscapeElement
                escapeButton={state.escapeButton}
                byAI={state.byAI}
                verne={state.verne}
                authorWork={state.authorWork}
                changeScene={state.changeScene}
                lastNormalSceneName={state.lastNormalSceneName}
            />
            <CloseX
                isOpen={state.genericModalIsOpen}
                closeFunc={() => dispatch({
                    type: 'SET_GENERIC_MODAL_IS_OPEN',
                    payload: false
                })}

            />
            <GenericModal isOpen={state.genericModalIsOpen} contentName={state.genericModalContent} />
            <Enter loaderVisibility={loaderVisibility} />
            <ReactAudioPlayer
                src={`${config.url.API_URL}/music/music2.mp3`}
                autoPlay={playing}
                ref={audioPlayer}
                loop={true}
                muted={false}
            />
            <Tutorial show={state.tutorialShown} />
        </div>
    )

}

export default App