import React from 'react'
import PropTypes from "prop-types"

import '../css/nightMode.css'

const NightMode = ({ light }) => {
    return (
        <div className={`nightLayer ${!light ? 'nightLayer__on' : ''}`}>

        </div>
    )
}

NightMode.propTypes = {
    light: PropTypes.bool,
}

export default NightMode
