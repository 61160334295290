import React from 'react'
import PropTypes from 'prop-types'

import '../css/musicNote.css'

// import noteIcon from '../img/note.svg'
import noteIcon from '../img/note.png'

function MusicNote ({ isPlaying, togglePlay }) {
    return (
        <div className={`music__icon ${isPlaying ? '' : 'music__icon__notPlay'}`} onClick={togglePlay}>
            <img src={noteIcon} alt="note" />
        </div>
    )
}

MusicNote.propTypes = {
    isPlaying: PropTypes.bool.isRequired,
    togglePlay: PropTypes.func.isRequired,
}

export default MusicNote
