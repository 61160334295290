import PropTypes from "prop-types"
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react'
import AppContext from '../AppContext.js'

import '../css/taskbar.css'

import MusicNote from './MusicNote'
import TaskbarItem from './TaskbarItem'

import hanaAuerovaImg from '../img/hanaauerova.png'

import aiImg from '../img/ai.png'
import klapkaImg from '../img/klapka.png'
import snowflakeImg from '../img/snowflake.png'
import shopBagImg from '../img/shopbag.png'
import bioImg from '../img/bio.png'
import paragraphImg from '../img/paragraph.png'
import brushImg from '../img/brush.png'
import brush2Img from '../img/brush-2.jpg'
import lightImg from '../img/light.png'
import kruzitkoImg from '../img/kruzitko2.png'

const Image = ({ src }) => {
    return (
        <img src={src} />
    )
}

Image.propTypes = {
    src: PropTypes.any
}

const Taskbar = ({ music, closeGenericModal }) => {
    const { state, dispatch } = useContext(AppContext)

    function changeScene (sceneName, params = {}) {
        state.changeScene(sceneName, params)
    }

    // eslint-disable-next-line no-unused-vars
    function openModal (contentName) {
        dispatch({
            type: 'SET_GENERIC_MODAL_IS_OPEN',
            payload: true
        })

        dispatch({
            type: 'SET_GENERIC_MODAL_CONTENT',
            payload: contentName
        })
    }

    return (
        <div className="taskbar">
            <div className="taskbar__left">
                <div className="taskbar__logo" onClick={() => {
                    closeGenericModal()
                    changeScene('scene1')
                }}>
                    <img src={hanaAuerovaImg} alt="logo" />
                </div>
                <TaskbarItem
                    icon={<Image src={aiImg} />}
                    text=""
                    onClick={() => {
                        closeGenericModal()
                        changeScene('scene16')
                    }}
                />
                <TaskbarItem
                    icon={<Image src={brushImg} />}
                    text=""
                    onClick={() => {
                        closeGenericModal()
                        changeScene('scene19')
                    }}
                />
                <TaskbarItem
                    icon={<Image src={brush2Img} />}
                    text=""
                    onClick={() => {
                        closeGenericModal()
                        changeScene('scene28')
                    }}
                />
                <TaskbarItem
                    icon={<Image src={klapkaImg} />}
                    text=""
                    onClick={() => {
                        closeGenericModal()
                        changeScene('scene10', { yaw: 1.5432457665176749, pitch: -0.16205528537375535 })
                    }}
                />
                <TaskbarItem
                    icon={<Image src={snowflakeImg} />}
                    text=""
                    onClick={() => {
                        closeGenericModal()
                        changeScene('scene15', { yaw: 2.4939150385556896, pitch: -0.056301698220664775 })
                    }}
                />
                <TaskbarItem
                    icon={<Image src={lightImg} />}
                    text=""
                    onClick={() => {
                        dispatch({ type: 'TOGGLE_LIGHT' })
                    }}
                />
            </div>
            <div className="taskbar__right">
                {/*
                <TaskbarItem
                    icon={<Image src={shopBagImg} />}
                    text=""
                    onClick={() => openModal('shop')}
                />
                */}
                <TaskbarItem
                    icon={<Image src={bioImg} />}
                    text=""
                    onClick={() => openModal('bio')}
                />
                <TaskbarItem
                    icon={<Image src={paragraphImg} />}
                    text=""
                    onClick={() => openModal('paragraph')}
                />
                <MusicNote isPlaying={music.isPlaying} togglePlay={music.togglePlay} />
            </div>
        </div>
    )
}

Taskbar.propTypes = {
    closeGenericModal: PropTypes.any,
    closePictureModal: PropTypes.func,
    music: PropTypes.shape({
        isPlaying: PropTypes.any,
        togglePlay: PropTypes.any
    })
}

export default Taskbar
