import PropTypes from "prop-types"
import React, { useContext } from 'react'

import AppContext from '../AppContext.js'

import '../css/topTaskbar.css'
import hanaAuerovaImg from '../img/hanaauerova.png'

function TopTaskbar ({ closeFunc }) {
    const { state } = useContext(AppContext)

    return (
        <div className="taskbar__top">
            <img src={hanaAuerovaImg} alt="logo" onClick={() => {
                state.changeScene('scene1')
                closeFunc()
            }} />
        </div>
    )
}

TopTaskbar.propTypes = {
    closeFunc: PropTypes.func
}

export default TopTaskbar
