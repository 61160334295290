import React from 'react'

import '../css/shopBag.css'

import shopImg from '../img/shop.jpg'

function ShopBag () {
    return (
        <div className="shopBag">
            <div className="shopBag__picture">
                <img src={shopImg} alt="shop hanka" />
            </div>
            <div className="shopBag__quote">
                &quot;Art washes away from the soul the dust of everyday life.&quot;
                <span className="shopBag__quote_signature">Pablo Picasso</span>
            </div>
            <div className="shopBag__text">
                To license, commission, or purchase my paintings, illustrations, animations, or photographs please contact me at <b>hana.auerova@seznam.cz</b>, or through my page on <a href="https://www.artfinder.com/artist/hana-auerova/#/" target="__blank">ArtFinder</a>.
            </div>
            <div className="shopBag__link">
                <span className="shopBag__link__text">
                    To see and buy my recent paintings,<br /> please visit my page at
                </span>
                <br />
                <a href="https://www.artfinder.com/artist/hana-auerova/#/" target="__blank">ArtFinder</a>
                <br /><br /><br /><br />
            </div>
        </div>
    )
}

export default ShopBag
