import PropTypes from 'prop-types'
import React from 'react'

// eslint-disable-next-line no-unused-vars
const MarzipanoInfo = ({ id, openPictureModal, openInfoModal, picture, text, header, shop, size, medium, author, price, rating }) => {
  const onClickInfo = (e) => {
    e.preventDefault()

    openPictureModal({
      picture,
      text,
      header,
      shop,
      size,
      medium,
      author,
      price,
      rating
    })
  }

  return (
    <div className="lds-ripple" id={id} onClick={openInfoModal ? openInfoModal : onClickInfo}>
      <div></div>
      <div></div>
    </div>
  )
}

MarzipanoInfo.propTypes = {
  author: PropTypes.string,
  header: PropTypes.string,
  id: PropTypes.string,
  medium: PropTypes.string,
  openInfoModal: PropTypes.any,
  openPictureModal: PropTypes.func,
  picture: PropTypes.string,
  price: PropTypes.string,
  rating: PropTypes.number,
  shop: PropTypes.string,
  size: PropTypes.string,
  text: PropTypes.string
}


export default MarzipanoInfo
