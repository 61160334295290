import PropTypes from "prop-types"
import React, { Component } from "react"

class MarzipanoPath extends Component {
  constructor (props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick (e) {
    e.preventDefault()

    this.props.changeScene(this.props.targetScene, {
      yaw: this.props.lookAtYaw,
      pitch: this.props.lookAtPitch,
    })
  }

  render () {
    return (
      <div className="lds-ripple lsd-ripple-white" id={this.props.id} onClick={this.onClick}>
        <div></div>
        <div></div>
      </div>
    )
  }
}

MarzipanoPath.propTypes = {
  changeScene: PropTypes.func,
  id: PropTypes.any,
  lookAtPitch: PropTypes.any,
  lookAtYaw: PropTypes.any,
  targetScene: PropTypes.any
}

export default MarzipanoPath


// <div
//         id={this.props.id}
//         className={classes}
//         data-hint="hint.css!"
//       >
//         <a href="#" onClick={this.onClick}>
//           <img src={this.props.image} alt="navigation hotspot" className={this.props.color || ''} />
//         </a>
//       </div>
