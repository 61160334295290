import PropTypes from "prop-types"
import React, { useRef, useEffect, useState, useContext } from 'react'
import useSound from 'use-sound'

import { config } from '../Constants'
import AppContext from '../AppContext'

import '../css/enterScreen.css'

import fingerImg from '../img/finger.png'
// import whipSound from '../assets/whip.mp3'
import cvrlikot from '../assets/cvrlikot.mp3'

// eslint-disable-next-line no-unused-vars
const Enter = ({ loaderVisibility }) => {
    const enterLoaderRef = useRef(null)
    const { dispatch } = useContext(AppContext)
    const [play] = useSound(cvrlikot)

    // eslint-disable-next-line no-unused-vars
    const [loaderRipple, setLoaderRipple] = useState(false)

    useEffect(() => {
        if (!loaderVisibility && !loaderRipple && enterLoaderRef.current.style.display !== 'none') {
            setTimeout(() => {
                setLoaderRipple(true)
            }, 2500)
        }
    }, [loaderVisibility, loaderRipple])

    function enter () {
        play()
        enterLoaderRef.current.classList.add('enter__loader__fadeout')
        setTimeout(() => {
            if (enterLoaderRef.current.style.display !== 'none') {
                enterLoaderRef.current.style.display = 'none'

                setTimeout(() => {
                    dispatch({
                        type: 'SET_TUTORIAL_SHOWN',
                        payload: true
                    })
                }, 1000)
            }
        }, 3000)
    }

    return (
        <div className={`enter__loader`} ref={enterLoaderRef}>
            <div className="enter__loader__dimmer">
                <div className="enter__loader_width_wrapper">
                    <div className="enter__loader__signature">
                        <img src={`${config.url.API_URL}/images/hanaauerova2.png`} />
                    </div>
                    <div className="enter__loader__artstudio">
                        <img src={`${config.url.API_URL}/images/artstudio2.png`} />
                    </div>
                    <div className={`enter__loader__ripple ${loaderRipple ? 'loader__ripple__visible loader__ripple__fadeIn' : ''}`} onClick={enter}>
                        <div className={`loader__lds-ripple`}><div></div><div></div></div>
                        <div className="loader__ripple_text">
                            <img src={fingerImg} alt="finger image" />
                        </div>
                    </div>

                    <div className={`enter__loader__ring ${!loaderVisibility ? 'loader__ring__fadeout' : ''}`}>
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </div>
                </div>
            </div>


        </div>
    )
}

Enter.propTypes = {
    loaderVisibility: PropTypes.any
}

export default Enter
