const prod = {
    url: {
        API_URL: `//artstudio.app`,
    }
}

const dev = {
    url: {
        API_URL: `http://localhost:9876`,
    }
}

// eslint-disable-next-line no-undef
export const config = process.env.NODE_ENV === `development` ? dev : prod