import React from 'react'

import '../css/bio.css'
import hankaImg from '../img/hankaProfilCropped.jpg'

function Bio () {
    return (
        <div className="bio">
            <div className="bio__picture">
                <img src={hankaImg} alt="hanka" />
            </div>
            <div className="bio__quote">
                &quot;For me, Art is the passionate journey to discover and express the realities that we see only through the soul.&quot;
                <span className="bio__quote_signature">Hana Auerova</span>
            </div>

            <div className="bio__achievements">
                <div className="bio__achievements__title">Publications</div>
                <div className="bio__achievements__list">
                    <div className="achievements__list">The Tune of Life, visual art (2021, Israel)</div>
                    <div className="achievements__list">Czech Film Fund - Catalogue Czech Animation (2019-2020, p. 30)</div>
                    <div className="achievements__list">Hana Auerová - Catalogue Blue Garden (2015)</div>
                    <div className="achievements__list">Hana Auerová - Animated Children AR book Ježíšek, Seznam TV, (2015)</div>
                    <div className="achievements__list">40 animated tales for children Kulaté pohádky, Seznam TV (2015-2016)</div>
                </div>
            </div>

            <div className="bio__achievements">
                <div className="bio__achievements__title">Solo Exhibitions</div>
                <div className="bio__achievements__list">
                    <div className="achievements__list">Melodies of the Seasons / Chiba, Japan (2023)</div>
                    <div className="achievements__list">Inspiration française / Marseille, EatRocks, France (2022)</div>
                    <div className="achievements__list">Modrá zahrada / Cultural center Alternativa - Zlín, Czechia (2019)</div>
                    <div className="achievements__list">Solo Exhibit and Premiere of Blue Garden Film / Maison de Quartier Art & Culture - Aix-en-Provence, France (2019)</div>
                </div>
            </div>

            <div className="bio__achievements">
                <div className="bio__achievements__title">Group Exhibitions</div>
                <div className="bio__achievements__list">
                    <div className="achievements__list">Staring cats / Lycée Daudet, Nîmes, France (2023)</div>
                    <div className="achievements__list">ArtExpo / New York City, Pier 94 & 36 - New York City, United States (2017,2022)</div>
                    <div className="achievements__list">London Calling! / The Crypt Gallery London - London, United Kingdom (2016)</div>
                    <div className="achievements__list">Summer Illusions, Castle of Holešov, Czech Republic (regularly)</div>
                </div>
            </div>

            <div className="bio__achievements">
                <div className="bio__achievements__title">Awards</div>
                <div className="bio__achievements__list">
                    <div className="achievements__list">WebTop100, Digital PR (1st, 2021)</div>
                    <div className="achievements__list">My First Million (1st, 2015)</div>
                    <div className="achievements__list">Open Society Fund (2nd, 1997)</div>
                </div>
            </div>

            <div className="bio__visitme">
                For more information and to see the latest updates from my studio, follow me on <br /> <a href="https://www.artfinder.com/artist/hana-auerova/me-at-work/#/" target="__blank">ArtFinder</a>
            </div>
            <br /><br /><br /><br />
        </div>
    )
}

export default Bio
