import PropTypes from "prop-types"
import React, { useReducer } from 'react'
import produce from 'immer'

const AppContext = React.createContext()

const Reducer = (draft, action) => {
    switch (action.type) {
        case 'SET_CHANGE_SCENE_FUNC':
            draft.changeScene = action.payload
            break
        case 'SET_BY_AI':
            draft.byAI = action.payload
            break
        case 'SET_AUTHOR_WORK':
            draft.authorWork = action.payload
            break
        case 'SET_VERNE':
            draft.verne = action.payload
            break
        case 'SET_CURRENT_SCENE_NAME':
            draft.currentSceneName = action.payload
            break
        case 'SET_LAST_SCENE_NAME':
            draft.lastSceneName = action.payload
            break
        case 'SET_LAST_NORMAL_SCENE_NAME':
            draft.lastNormalSceneName = action.payload
            break
        case 'SET_ESCAPE_BUTTON':
            draft.escapeButton = action.payload
            break
        case 'SET_GENERIC_MODAL_IS_OPEN':
            draft.genericModalIsOpen = action.payload
            break
        case 'SET_GENERIC_MODAL_CONTENT':
            draft.genericModalContent = action.payload
            break
        case 'SET_TUTORIAL_SHOWN':
            draft.tutorialShown = action.payload
            break
        case 'SET_PICTURE_MODAL_CONTENT':
            draft.pictureModalContext = action.payload
            break
        case 'INCREMENT':
            draft.globalCounter.counter = draft.globalCounter.counter + 1
            break
        case 'DECREMENT':
            draft.globalCounter.counter = draft.globalCounter.counter - 1
            break
        case 'TOGGLE_LIGHT':
            draft.light = !draft.light
            break
        default:
            return draft
    }

    return
}

const curriedReducer = produce(Reducer)

const AppContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(curriedReducer, {
        changeScene: null,
        byAI: false,
        authorWork: false,
        verne: false,
        escapeButton: false,
        currentSceneName: null,
        lastSceneName: null,
        lastNormalSceneName: null,
        genericModalIsOpen: false,
        genericModalContent: null,
        pictureModalContent: null,
        tutorialShown: false,
        globalCounter: { counter: 0 },
        light: true,
    })

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

AppContextProvider.propTypes = {
    children: PropTypes.any
}

export default AppContext
export { AppContextProvider }
