import PropTypes from "prop-types"
import React, { Component } from "react"
import ReactDOM from 'react-dom'

import _ from "lodash"

import { config } from '../Constants'

import MarzipanoPath from "./MarzipanoPath"
import MarzipanoInfo from "./MarzipanoInfo"
import MarzipanoVideo from "./MarzipanoVideo"

import Marzipano from "marzipano"

class MyMarzipano extends Component {
    constructor (props) {
        super(props)
        /**
         * Marzipano STUFF
         */
        this.panoViewer = null
        this.geometry = null
        this.limiter = null

        /**
         * APP STUFF
         */
        this.entryPoint = null
        this.entryPointName = ''
        this.currentSceneName = ""
        this.scenesBuffer = {}
        this.paths = []
        this.infos = []
        this.videos = []
        this.sceneTextureCounter = {}
        this.intervalStore = null
        this.sceneRenderOrder = []
        this.imageCacheList = {}

        /**
         * EXTERNAL DEPENDENCY
         */
        this.loaderOn = this.props.loaderControl.loaderOn
        this.loaderOff = this.props.loaderControl.loaderOff
        // this.isItAi = this.props.isItAi

        /**
         * GLOBAL CONTEXT
         */
        this.contextDispatch = this.props.context.dispatch

        this.contextDispatch({
            type: 'SET_CHANGE_SCENE_FUNC',
            payload: this.changeScene
        })
    }

    componentWillUnmount () {
        //console.log('componentWillUnmount')
        const scenesBuffer = this.scenesBuffer

        /**
         * Je potřeba před odstraněním komponenty zrušit všechny hotspoty
         */
        for (let key in scenesBuffer) {
            const scene = scenesBuffer[key].scene
            scenesBuffer[key].hotspost.forEach(el => scene.hotspotContainer().destroyHotspot(el))
        }

        // a zrušit samotné marzipano
        this.panoViewer.destroyAllScenes()


        this.panoViewer = null
        this.entryPoint = null
        this.currentSceneName = ""
        this.scenesBuffer = {}
        this.paths = []
        this.infos = []
        this.sceneTextureCounter = {}
        this.intervalStore = null
        this.sceneRenderOrder = {}

        for (let key in this.imageCacheList) {
            for (let item of this.imageCacheList[key].images) {
                item.remove()
            }

        }

        this.imageCacheList = {}

    }

    componentDidMount () {
        // console.log('componentDidMount')
        if (this.props.scenes && !this.entryPoint) {
            this.componentDidUpdate()
        }
    }

    componentDidUpdate () {
        // console.log('componentDidUpdate')
        /**
         * Potřebujeme aby vystavení scén počkalo na příchozí definice s nadřazené komponenty. (App)
         * Api call nechceme dělat v této komponentě, ale globálně. Zároveň nemůžeme dovolit aby se
         * Marzipano inicializovalo pri každé změně dat.
         *
         * ComponentDidMount funkce byla první volba před tím, než se začaly tahat data z API a byly
         * statické. Po te co se data začala získávat z API je nutné počkat až přijdou. Tuto událost
         * zajistí componentDidUpdate. Ovšem nechceme po každé změně znova spouštět kód Marzipana.
         * Takže se kontroluje jestli jsou data už nějak naplněna a pokud ještě neexistuje "entryPoint",
         * tak inicializace Marzipana ještě neproběhla a může začít. ComponentDidUpdate se spouští až 
         * ve chvíli kdy componentDidMount už někdy v minulosti proběhlo. Takže by mělo být zajištěno, 
         * že Marzipano nespadne na tom, že neexistuje element do kterého by se mohlo nahrát.
         */
        if (!_.isEmpty(this.props.scenes) && !this.entryPoint) {
            this.panoViewer = new Marzipano.Viewer(this.pano) // Hlavní viewer pro scény

            /**
             * Geometry a Limiter jsou pro všechny scény stejné
             */
            this.geometry = new Marzipano.CubeGeometry([{ tileSize: 2048, size: 2048 }])
            this.limiter = Marzipano.RectilinearView.limit.traditional(2048, (100 * Math.PI) / 180)

            this.sceneRenderOrder = this.sceneOrder(this.props.scenes)

            /**
             * Jako první se pošle do zpracování vstupní scéna
             */
            const sceneKey = this.sceneRenderOrder.shift()
            const scene = this.buildScene({
                key: sceneKey,
                sceneData: this.props.scenes[sceneKey]
            })

            this.loader(sceneKey)

            scene.view()._fov = 1
            scene.view().setParameters({
                yaw: _.get(this.props.scenes[sceneKey], 'lookAt.yaw', 0),
                pitch: _.get(this.props.scenes[sceneKey], 'lookAt.pitch', 0),
            })

            /**
            * GLOBAL - CURRENT SCENE NAME
            */
            this.contextDispatch({
                type: 'SET_CURRENT_SCENE_NAME',
                payload: sceneKey
            })

            /**
            * GLOBAL - LAST SCENE NAME
            */
            this.contextDispatch({
                type: 'SET_LAST_SCENE_NAME',
                // payload: this.currentSceneName
                payload: sceneKey
            })

            /**
            * GLOBAL - LAST NORMAL SCENE NAME
            */
            if (!this.scenesBuffer[sceneKey].escapeButton) {
                this.contextDispatch({
                    type: 'SET_LAST_NORMAL_SCENE_NAME',
                    // payload: this.currentSceneName
                    payload: sceneKey
                })
            }

            scene.switchTo()
        }
    }

    /**
     * Vytvoří seřazený seznam pro vytváření scén
     * 
     * @param {*} scenes 
     */
    sceneOrder (scenes) {
        const order = []
        let neighbours = []

        for (let key in scenes) {
            if (scenes[key].entryPoint === true) {
                order.push(key)
                neighbours = scenes[key].paths.map(i => i.scene)
                break
            }
        }

        for (let key in scenes) {
            if (neighbours.includes(key)) {
                order.push(key)
            }
        }

        for (let key in scenes) {
            if (scenes[key].entryPoint !== true && !neighbours.includes(key)) {
                order.push(key)
            }
        }

        return order
    }

    /**
     * Metoda přednahrává obrázky projekce pro danou scénu. Aby bylo možné procházet scény
     * bez čekání na nahrávané obrázky. Každý obrázek pro sestavení scény se postupně 
     * nahrává do objektu "Image", který je uložen do seznamu "imageCacheList" ke konkrétní
     * scéně pro účely následného úklidu při destrukci react objektu. Tím že donutíme prohlížeč
     * nahrát obrázky "ručně", tak je uloží do cache. A ve chvíli kdy Marzipano bude sestavovat 
     * scénu, do které právě vcházíme, si už vezme obrázky z cache a nebude zdržovat stahováním 
     * ze serveru.
     * 
     * Podobným mechanismem jako u kontroly jestli je vstupní obrazovka připravena postupujeme
     * i zde. Víme že počet stran kostky je 6. Takže u každého Image objektu nastavím callback
     * nahrání obrázku na funkci, která ve struktuře "imageCacheList" inkrementuje počítadlo
     * k dané scéně o jedna. Ve chvíli kdy je hodnota na šesti víme, že obrázky pro scénu jsou
     * nahrány (v cache). A pustíme zpracování následující scény pomocí metody "buildRestOfScenes".
     * @param {string} sceneKey 
     */
    cacheScenesImages (sceneKey) {
        const iNames = ['b.jpg', 'd.jpg', 'f.jpg', 'l.jpg', 'r.jpg', 'u.jpg']
        if (!this.imageCacheList[sceneKey]) {
            this.imageCacheList[sceneKey] = {
                images: [],
                done: false,
                loadedCount: 0,
            }
        }


        for (let fileName of iNames) {
            const img = new Image()

            img.onload = () => {
                if (this.imageCacheList[sceneKey].loadedCount < 6) {
                    this.imageCacheList[sceneKey].loadedCount += 1
                }

                if (this.imageCacheList[sceneKey].loadedCount === 6) {
                    this.buildRestOfScenes()
                }
            }

            img.src = `${config.url.API_URL}/cubemaps/${sceneKey}/${fileName}`
            this.imageCacheList[sceneKey].images.push(img)
        }
    }


    /**
     * Build actual scene with custom hotspots
     * 
     * @param {*} param0 
     */
    buildScene ({ key, sceneData }) {
        this.cacheScenesImages(key)

        const view = new Marzipano.RectilinearView(null, this.limiter)

        const source = Marzipano.ImageUrlSource.fromString(`${config.url.API_URL}/cubemaps/${sceneData.source}/{f}.jpg`)

        const scene = this.panoViewer.createScene({
            source,
            geometry: this.geometry,
            view,
        })

        /**
         * TEXTURE_LOAD Listener
         * 
         * Každá scéna se skládá z šesti stran kostky. Při zobrazení scény se nahrávají textury.
         * Pro potřeby nahrávací obrazovky při prvním zobrazení vstupní scény je nutné počkat
         * dokud nebudou textury nahrány. Ve chvíli kdy "sceneTextureLoader" pro konkrétní 
         * scénu obsahuje číslo šest, znamená že všechny textury byly nahrány. Tuto kontrolu
         * provádí "loader" při první vstupu do vstupní scény. tzn: Zobrazuje loader dokud
         * číslo není šest.
         */
        const that = this
        scene.layer().textureStore().addEventListener("textureLoad", function () {
            if (!that.sceneTextureCounter[key]) {
                that.sceneTextureCounter[key] = 0
            }

            if (that.sceneTextureCounter[key] < 6) {
                that.sceneTextureCounter[key] += 1
            }
        })

        /**
         * Po celou dobu životnosti aplikace drží "scenesBuffer" klíčové informace a objekty
         * pro každou scénu.
         */
        this.scenesBuffer[key] = {
            view,
            source,
            scene,
            lookAt: {
                yaw: _.get(sceneData, "lookAt.yaw", 0),
                pitch: _.get(sceneData, "lookAt.pitch", 0),
            },
            hotspost: [],
            videos: [],
            ai: sceneData.ai ? true : false,
            authorWork: sceneData.authorWork ? true : false,
            verne: sceneData.verne ? true : false,
            escapeButton: sceneData.escapeButton ? true : false,
        }

        if (sceneData.entryPoint === true) {
            this.entryPoint = scene
            this.currentSceneName = key
            this.entryPointName = key
        }

        /**
         * Zpracování path hotspotů
         */
        for (const p of sceneData.paths) {
            this.paths.push(p)

            // vytvoříme react objekt
            const pathElement = (
                <MarzipanoPath
                    key={`path__${p.id}`}
                    cleanId={`${p.id}`}
                    id={`path__${p.id}`}
                    targetScene={p.scene}
                    lookAtYaw={_.get(p, "lookAt.yaw", 0)}
                    lookAtPitch={_.get(p, "lookAt.pitch", 0)}
                    changeScene={this.changeScene}
                />
            )

            // v DOMu vytvoříme prázdný div element
            const pathRootElement = document.createElement('div')

            // ten se stane hotspotem pro konkrétní scénu s pozičními parametry
            const hotspot = scene.hotspotContainer().createHotspot(pathRootElement, {
                yaw: p.yaw,
                pitch: p.pitch,
            })

            // nově vytvořený hotspot uložíme ke konkrétní sceně za účelem čištění při "unmount"
            this.scenesBuffer[key].hotspost.push(hotspot)

            // ReactDOM.render(React.cloneElement(pathElement), hotspot.domElement())
            // do hotspotu vyrenderujeme react objekt do vytvořeného hotspotu
            ReactDOM.render(pathElement, hotspot.domElement())
        }

        /**
         * Zpracování info hotspotů
         */
        if (sceneData.infos) {
            for (const i of sceneData.infos) {
                this.infos.push(i)
                let data = i.data

                if (_.isString(data.refersTo)) {
                    const [toScene, toInfoId] = data.refersTo.split('.')
                    const targetInfo = this.props.scenes[toScene].infos.filter(item => item.id === toInfoId)

                    if (targetInfo[0]) {
                        data = targetInfo[0].data
                    }
                }

                let infoElement = null

                if (i.type === 'infoModal') {
                    infoElement = (
                        <MarzipanoInfo
                            key={`info__${i.id}`}
                            id={`info__${i.id}`}
                            openInfoModal={() => {
                                this.props.context.dispatch({
                                    type: 'SET_GENERIC_MODAL_IS_OPEN',
                                    payload: true
                                })

                                this.props.context.dispatch({
                                    type: 'SET_GENERIC_MODAL_CONTENT',
                                    payload: data.modalName
                                })
                            }}
                        />
                    )
                } else {
                    infoElement = (
                        <MarzipanoInfo
                            key={`info__${i.id}`}
                            id={`info__${i.id}`}
                            picture={data.picture}
                            text={data.text}
                            header={data.header}
                            shop={data.shop}
                            size={data.size}
                            medium={data.medium}
                            author={data.author}
                            price={data.price}
                            rating={data.rating}
                            openPictureModal={this.props.openPictureModal}
                        />
                    )
                }

                const infoRootElement = document.createElement('div')

                const hotspot = scene.hotspotContainer().createHotspot(infoRootElement, {
                    yaw: i.yaw,
                    pitch: i.pitch,
                })

                this.scenesBuffer[key].hotspost.push(hotspot)

                // ReactDOM.render(React.cloneElement(infoElement), hotspot.domElement())
                ReactDOM.render(infoElement, hotspot.domElement())
            }
        }

        /**
         * Zpracování video hotspotů
         */
        if (sceneData.videos) {
            for (const v of sceneData.videos) {
                this.videos.push(v)

                const videoElement = (
                    <MarzipanoVideo
                        id={`video__${v.id}`}
                        key={`video__${v.id}`}
                        url={v.data.url}
                        width={v.width}
                        height={v.height}
                    />
                )

                const videoRootElement = document.createElement('div')

                const hotspot = scene.hotspotContainer().createHotspot(videoRootElement,
                    {
                        yaw: v.yaw,
                        pitch: v.pitch,
                    },
                    {
                        perspective: {
                            radius: v.radius,
                            extraTransforms: v.extraTransform,
                        }
                    }
                )

                this.scenesBuffer[key].hotspost.push(hotspot)

                ReactDOM.render(videoElement, hotspot.domElement())
            }
        }


        return scene
    }

    /**
     * Metoda se stará o vytvoření následující scény ze seznamu "sceneRenderOrder"
     */
    buildRestOfScenes () {
        if (this.sceneRenderOrder.length === 0) {
            return
        }

        const sceneKey = this.sceneRenderOrder.shift()

        this.buildScene({
            key: sceneKey,
            sceneData: this.props.scenes[sceneKey]
        })

    }

    /**
     * Loader je použit při prvním spuštění stránky a čeká dokud není první scéna nahrána.
     * 
     * Rozpoznání kdy je scéna nahrána se provádí pomocí struktury "sceneTextureCounter" 
     * do které Marzipano na základě události "textureLoaded" zvyšuje počítadlo u konkrétní scény.
     * Ve chvíli, kdy hodnota dosáhne hodnoty šest víme, že všech šest stran kostky bylo zpracováno.
     * 
     * Funkce se pokouší na první dobrou zjistit jestli je už hodnota 6 a pokud ne, vytvoří
     * interval, který co půl sekundy zjišťuje jestli se situace už změnila k lepšímu.
     * 
     * Ve chvíli, kdy je vstupní scéna vyrendrována započne vytváření zbytku scén pomocí
     * metody "buildRestOfScenes".
     * @param {*} sceneName 
     */
    loader = (sceneName) => {
        if (this.sceneTextureCounter[sceneName] === 6) {
            if (sceneName === this.entryPointName) {
                this.buildRestOfScenes()
            }

            clearInterval(this.intervalStore)
            this.loaderOff()
            return
        }

        this.loaderOn()
        clearInterval(this.intervalStore)

        const that = this
        this.intervalStore = setInterval(function () {
            if (that.sceneTextureCounter[sceneName] === 6) {
                if (sceneName === that.entryPointName) {
                    that.buildRestOfScenes()
                }
                that.loaderOff()
                clearInterval(that.intervalStore)
            }
        }, 500)
    }

    /* protože nechci používat "this.changeScene = this.changeScene.bind(this)" v konstruktoru
    za normálních okolnosti je change scene volána v jiném kontextu a nevidí pres this na komponentu
    tohle to vyřeší */
    changeScene = (sceneName, data) => {
        if (!this.scenesBuffer[sceneName] || !this.imageCacheList[sceneName] || (this.imageCacheList[sceneName] && this.imageCacheList[sceneName].loadedCount < 6)) {
            return
        }

        this.contextDispatch({ type: 'INCREMENT' })

        /**
         * Pořadí získání souřadnic pro pohled při vstupu do scény
         * - path_hotspot
         * - výchozí souřadnice u scény
         * - 0, 0
         */
        const yaw = _.get(data, 'yaw', null) || _.get(this.scenesBuffer[sceneName], 'lookAt.yaw', 0)
        const pitch = _.get(data, 'pitch', null) || _.get(this.scenesBuffer[sceneName], 'lookAt.pitch', 0)

        const scene = this.scenesBuffer[sceneName].scene

        /**
        * GLOBAL - LAST SCENE NAME
        */
        this.contextDispatch({
            type: 'SET_LAST_SCENE_NAME',
            // payload: this.currentSceneName
            payload: this.props.context.state.currentSceneName
        })

        /**
        * GLOBAL - LAST NORMAL SCENE NAME
        */
        if (this.props.context.state.currentSceneName && !this.scenesBuffer[this.props.context.state.currentSceneName].escapeButton) {
            this.contextDispatch({
                type: 'SET_LAST_NORMAL_SCENE_NAME',
                // payload: this.currentSceneName
                payload: this.props.context.state.currentSceneName
            })
        }

        this.currentSceneName = sceneName
        scene.view()._fov = 1
        scene.view().setParameters({
            yaw,
            pitch,
        })

        /**
         * GLOBAL - CURRENT SCENE NAME
         */
        this.contextDispatch({
            type: 'SET_CURRENT_SCENE_NAME',
            payload: sceneName
        })

        scene.switchTo()

        /**
        * GLOBAL - MADE BY AI
        */
        if (this.scenesBuffer[sceneName].ai) {
            this.contextDispatch({
                type: 'SET_BY_AI',
                payload: true
            })
        } else {
            this.contextDispatch({
                type: 'SET_BY_AI',
                payload: false
            })
        }

        /**
        * GLOBAL - ESCAPE BUTTON
        */
        if (this.scenesBuffer[sceneName].escapeButton) {
            this.contextDispatch({
                type: 'SET_ESCAPE_BUTTON',
                payload: true
            })
        } else {
            this.contextDispatch({
                type: 'SET_ESCAPE_BUTTON',
                payload: false
            })
        }

        /**
         * GLOBAL - AUTHOR WORK
         */
        if (this.scenesBuffer[sceneName].authorWork) {
            this.contextDispatch({
                type: 'SET_AUTHOR_WORK',
                payload: true
            })
        } else {
            this.contextDispatch({
                type: 'SET_AUTHOR_WORK',
                payload: false
            })
        }

        /**
        * GLOBAL - VERNE
        */
        if (this.scenesBuffer[sceneName].verne) {
            this.contextDispatch({
                type: 'SET_VERNE',
                payload: true
            })
        } else {
            this.contextDispatch({
                type: 'SET_VERNE',
                payload: false
            })
        }
    }

    panoClick = (e) => {
        console.table({
            click: this.scenesBuffer[this.currentSceneName].view.screenToCoordinates({
                x: e.clientX,
                y: e.clientY,
            }),
            view: {
                yaw: this.scenesBuffer[this.currentSceneName].view._yaw,
                pitch: this.scenesBuffer[this.currentSceneName].view._pitch,
                fov: this.scenesBuffer[this.currentSceneName].view._fov,
                currentScene: this.currentSceneName,
            },
        })
    }

    render () {
        return (
            <div className="pano" ref={(pano) => (this.pano = pano)} onClick={this.panoClick} />
        )
    }
}

MyMarzipano.propTypes = {
    context: PropTypes.shape({
        contextDispatch: PropTypes.any,
        dispatch: PropTypes.any,
        state: PropTypes.any
    }),
    isItAi: PropTypes.any,
    loaderControl: PropTypes.shape({
        loaderOff: PropTypes.any,
        loaderOn: PropTypes.any
    }),
    openPictureModal: PropTypes.any,
    scenes: PropTypes.any
}



export default MyMarzipano
