import PropTypes from "prop-types"
import React from 'react'

import '../css/taskbarItem.css'

const TaskbarItem = ({ icon, text, onClick }) => {
    return (
        <div className="taskbar__item" onClick={onClick}>
            <div className="taskbar__item__icon">{icon}</div>
            <div className="taskbar__item__text">{text}</div>
        </div>
    )
}

TaskbarItem.propTypes = {
    icon: PropTypes.any,
    onClick: PropTypes.any,
    text: PropTypes.any
}

export default TaskbarItem
