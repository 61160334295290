import React, { useContext } from 'react'
import AppContext from '../AppContext.js'

import { config } from '../Constants'

import Url from 'url-parse'

import '../css/painting.css'

function Painting () {
    const { state } = useContext(AppContext)
    const data = state.pictureModalContext

    function getHostname (url) {
        const u = new Url(url)

        if (u.hostname.startsWith('www.')) {
            return u.hostname.slice(4)
        }

        return u.hostname
    }

    return (
        <div className="painting">
            <div className="painting__picture">
                <img src={`${config.url.API_URL}/paintings/${data.picture}`} alt="painting" />
            </div>
            <div className="painting__header">{data.header}</div>
            <div className="painting__info">
                <div>{data.medium}</div>
                <div>{data.size}</div>
                <div>{data.author}</div>
            </div>
            <div className="painting__text">{data.text}</div>
            <div className="painting__purchase">
                <div className="painting__purchase__button" onClick={() => window.open(data.shop, '_blank')}>
                    Buy at <span className="purchase__button__link">{getHostname(data.shop)}</span>
                </div>
            </div>
            <br /><br /><br />
        </div>
    )
}

export default Painting
