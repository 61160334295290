import PropTypes from "prop-types"
import React from 'react'

import '../css/modalClose.css'

import iksIcon from '../img/iks.png'

function CloseX ({ isOpen, closeFunc }) {
    return (
        <div
            className={`generic__modal__close ${!isOpen ? 'generic__modal__close__hidden' : ''}`}
            onClick={closeFunc}
        >
            <img src={iksIcon} alt="iks" />
        </div>
    )
}

CloseX.propTypes = {
    closeFunc: PropTypes.any,
    isOpen: PropTypes.any
}

export default CloseX
