import React from 'react'

import webTopImage from '../img/ribbon2.jpg'

const Ribbon2 = () => {
    return (
        <div
            id="awwwards2"
            style={{ position: 'fixed', zIndex: 9999, transform: 'translateY(-50%)', top: '25%', left: 0 }}
        >
            <a href="https://www.webtop100.cz/vysledky/2021/digitalni-pr" target="_blank" rel="noreferrer">
                <img src={webTopImage} width="53" alt="ribbon2" />
            </a>
        </div>
    )
}

export default Ribbon2
