import PropTypes from "prop-types"
import React from 'react'


const MarzipanoVideo = ({ id, url, width, height }) => {
    return (
        <iframe
            id={id}
            src={url}
            width={`${width ? width : 640}`}
            height={`${height ? height : 360}`}
            frameBorder="0"
            allow="autoplay"
        ></iframe>
    )
}

MarzipanoVideo.propTypes = {
    id: PropTypes.any,
    url: PropTypes.any,
    width: PropTypes.any,
    height: PropTypes.any,
}

export default MarzipanoVideo
