import PropTypes from "prop-types"
import React from 'react'

import Modal from 'react-modal'

import '../css/genericModal.css'

import Bio from './Bio'
import ShopBag from './ShopBag'
import Paragraph from './Paragraph'
import Painting from './Painting'
import Vila from './Vila'

const customStyles = {
    content: {
        height: "100%",
        background: 'none',
        padding: 0,
        border: 0,
        inset: 0,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        overflow: 'auto',
    },
    overlay: {
        zIndex: 1010,
        bottom: '50px',
        backgroundColor: 'rgba(255,255,255,0.9)',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
    }
}

function GenericModal ({ isOpen, contentName }) {
    return (
        <Modal
            isOpen={isOpen}
            style={customStyles}
            contentLabel='Example modal'
            ariaHideApp={false}
        >
            {contentName === 'bio' && <Bio />}
            {contentName === 'shop' && <ShopBag />}
            {contentName === 'paragraph' && <Paragraph />}
            {contentName === 'painting' && <Painting />}
            {contentName === 'vila' && <Vila />}
        </Modal>
    )
}

GenericModal.propTypes = {
    contentName: PropTypes.any,
    isOpen: PropTypes.any
}

export default GenericModal
