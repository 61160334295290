import React from 'react'
import ReactPlayer from 'react-player'

import '../css/vila.css'
import vvv from '../assets/vila-model.m4v'

function Vila () {
    return (
        <div className="vila">
            <ReactPlayer
                url={vvv}
                playing={true}
                loop={true}
                width={Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)}
                height='120%'
            />
        </div>
    )
}

export default Vila
