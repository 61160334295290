import PropTypes from "prop-types"
import React from 'react'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faTimes } from '@fortawesome/free-solid-svg-icons'

import '../css/escape.css'

import iksIcon from '../img/iks.png'
import lookIcon from '../img/lookaround.png'
import aiTextIcon from '../img/aiText2.png'
import verneTextIcon from '../img/verneText.png'
import handTextIcon from '../img/handText2.png'

function EscapeElement ({ byAI, authorWork, verne, escapeButton, changeScene, lastNormalSceneName }) {
    return (
        <div className={`escape ${escapeButton ? 'escape__show' : ''}`}>
            <div className="escape__radar">
                <img className="escape__radar__look" src={lookIcon} alt="look" />
                {byAI &&
                    <img className="escape__radar__aitext" src={aiTextIcon} alt="ai text" />
                }

                {authorWork &&
                    <img className="escape__radar__aitext" src={handTextIcon} alt="hand made text" />
                }

                {verne &&
                    <img className="escape__radar__aitext" src={verneTextIcon} alt="jules verne by hanka" />
                }
                <div className="escape__close" onClick={() => changeScene(lastNormalSceneName)}>
                    <img src={iksIcon} alt="iks" />
                </div>
            </div>
        </div>
    )
}

EscapeElement.propTypes = {
    authorWork: PropTypes.any,
    byAI: PropTypes.any,
    verne: PropTypes.any,
    changeScene: PropTypes.any,
    escapeButton: PropTypes.any,
    lastNormalSceneName: PropTypes.any
}

export default EscapeElement
